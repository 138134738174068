import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';

import './index.scss';
// import { Box } from '../../modules/common/components/box';
import { Row } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';

const EsIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page eversoul'} game="es">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Eversoul wiki</h1>
        <h2>
          Prydwen.gg is a wiki for Eversoul, an idle gacha game developed by
          Nine Ark and published by Kakao Games.
        </h2>
      </div>
      <SectionHeader title="Shortcuts" />
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/eversoul/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/eversoul/categories/category_intro.jpg"
              alt="Introduction"
            />
          }
          updated
        />
        <CategoryCard
          title="Reroll guide"
          link="/eversoul/guides/reroll"
          image={
            <StaticImage
              src="../../images/eversoul/categories/category_reroll.jpg"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Beginner's guide"
          link="/eversoul/guides/beginner-guide"
          image={
            <StaticImage
              src="../../images/eversoul/categories/category_beginner.jpg"
              alt="Beginner's guide"
            />
          }
          updated
        />
        <CategoryCard
          title="Team building"
          link="/eversoul/guides/team-building"
          image={
            <StaticImage
              src="../../images/eversoul/categories/category_team.png"
              alt="Team building"
            />
          }
        />
        <CategoryCard
          title="Tips and tricks"
          link="/eversoul/guides/tips-and-tricks"
          image={
            <StaticImage
              src="../../images/eversoul/categories/category_tips.png"
              alt="Tips and trick"
            />
          }
        />
      </Row>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default EsIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="Eversoul | Prydwen Institute"
    description="Prydwen.gg is a wiki for Eversoul, an idle gacha game developed by
    Nine Ark and published by Kakao Games."
    game="eversoul"
  />
);
